import { Button } from "@mui/material";
import { useState } from "react";
import { useRecoilState } from "recoil";
import {
  searchRangeDateState,
  selectedDateFilterState,
} from "recoil/stateStore";

export default function DateSearch({}) {
  const today = new Date();
  const todayString = today.toISOString().split("T")[0];

  // 1주일 전 날짜 계산
  const twoWeekAgo = new Date(today);
  twoWeekAgo.setDate(today.getDate() - 14);
  const twoWeekAgoString = twoWeekAgo.toISOString().split("T")[0];

  // 1주일 후 날짜 계산
  const twoWeekLater = new Date(today);
  twoWeekLater.setDate(today.getDate() + 14);
  const twoWeekLaterString = twoWeekLater.toISOString().split("T")[0];

  const [rangeDate, setRangeDate] = useRecoilState(searchRangeDateState);
  const [selectedButton, setSelectedButton] = useRecoilState(
    selectedDateFilterState
  );

  // useEffect(() => {
  //   setRangeDate(["", ""]);
  // }, []);

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedStartDate = event.target.value;
    // if (selectedStartDate > rangeDate[1]) {
    //   setRangeDate([rangeDate[1], rangeDate[1]]);
    // } else {
    setRangeDate([selectedStartDate, rangeDate[1]]);
    // }
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedEndDate = event.target.value;
    // if (selectedEndDate < rangeDate[0]) {
    //   setRangeDate([rangeDate[0], rangeDate[0]]);
    // } else {
    setRangeDate([rangeDate[0], selectedEndDate]);
    // console.log(rangeDate);
    // }
  };

  // useEffect(() => {
  //   if (rangeDate[1] && rangeDate[1] < rangeDate[0]) {
  //     // toast.warning("잘못된 기간입니다.");
  //     setRangeDate([rangeDate[0], ""]);
  //   }
  // }, [rangeDate]);

  // 전체 버튼
  const handleAllClick = () => {
    if (selectedButton === "all") {
      setRangeDate([twoWeekAgoString, twoWeekLaterString]); // 초기 상태로 설정

      setSelectedButton(""); // 비활성화
    } else {
      setRangeDate(["", ""]);
      setSelectedButton("all");
    }
  };

  // 오늘 버튼
  const handleTodayClick = () => {
    if (selectedButton === "today") {
      setRangeDate([twoWeekAgoString, twoWeekLaterString]); // 초기 상태로 설정
      setSelectedButton(""); // 비활성화
    } else {
      const todayDate = new Date();
      setSelectedButton("today");
      setRangeDate([todayDate.toISOString().split("T")[0], todayString]);
    }
  };

  // 어제 버튼
  const handleYesterdayClick = () => {
    if (selectedButton === "yesterday") {
      setRangeDate([twoWeekAgoString, twoWeekLaterString]); // 초기 상태로 설정

      setSelectedButton(""); // 비활성화
    } else {
      const todayDate = new Date();
      const yesterday = new Date(todayDate);
      yesterday.setDate(yesterday.getDate() - 1);
      setSelectedButton("yesterday");
      setRangeDate([
        yesterday.toISOString().split("T")[0],
        todayDate.toISOString().split("T")[0],
      ]);
    }
  };

  // 1주일 버튼
  const handleOneWeekClick = () => {
    if (selectedButton === "oneWeek") {
      setRangeDate([twoWeekAgoString, twoWeekLaterString]); // 초기 상태로 설정

      setSelectedButton(""); // 비활성화
    } else {
      const todayDate = new Date();
      const twoWeekAgo = new Date(todayDate);
      twoWeekAgo.setDate(todayDate.getDate() - 7);
      setSelectedButton("oneWeek");
      setRangeDate([
        twoWeekAgo.toISOString().split("T")[0],
        todayDate.toISOString().split("T")[0],
      ]);
    }
  };

  // 1개월 버튼
  const handleOneMonthClick = () => {
    if (selectedButton === "oneMonth") {
      setRangeDate([twoWeekAgoString, twoWeekLaterString]); // 초기 상태로 설정

      setSelectedButton(""); // 비활성화
    } else {
      const todayDate = new Date();
      const oneMonthAgo = new Date(todayDate);
      oneMonthAgo.setMonth(todayDate.getMonth() - 1);
      setSelectedButton("oneMonth");
      setRangeDate([
        oneMonthAgo.toISOString().split("T")[0],
        todayDate.toISOString().split("T")[0],
      ]);
    }
  };

  return (
    <div className="dateSearch_content">
      <div className="term_btn_box">
        <Button
          variant="outlined"
          className={selectedButton === "all" ? "selected" : ""}
          onClick={handleAllClick}
          sx={{ color: "#104573", borderColor: "#104573" }}
        >
          전체
        </Button>
        <Button
          variant="outlined"
          className={selectedButton === "oneMonth" ? "selected" : ""}
          onClick={handleOneMonthClick}
          sx={{ color: "#104573", borderColor: "#104573" }}
        >
          1개월
        </Button>
        <Button
          variant="outlined"
          className={selectedButton === "oneWeek" ? "selected" : ""}
          onClick={handleOneWeekClick}
          sx={{ color: "#104573", borderColor: "#104573" }}
        >
          1주
        </Button>
        <Button
          variant="outlined"
          className={selectedButton === "yesterday" ? "selected" : ""}
          onClick={handleYesterdayClick}
          sx={{ color: "#104573", borderColor: "#104573" }}
        >
          어제
        </Button>
        <Button
          variant="outlined"
          className={selectedButton === "today" ? "selected" : ""}
          onClick={handleTodayClick}
          sx={{ color: "#104573", borderColor: "#104573" }}
        >
          오늘
        </Button>
      </div>
      <div className="calendar_box">
        {/* <label htmlFor="startDate">기준일</label> */}
        <input
          id="startDate"
          type="date"
          value={rangeDate[0]}
          onChange={handleStartDateChange}
        />
        <span>~</span>
        <input
          type="date"
          value={rangeDate[1]}
          onChange={handleEndDateChange}
        />
      </div>
    </div>
  );
}
