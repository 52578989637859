import "styles/LoadingStyle.css";

export const CustomLoadingForm = () => {

    return (
        <div className="loading-container">
            <div className="loading"></div>
            <div id="loading-text">Loading</div>
        </div>
    );
}

export default CustomLoadingForm;