import { ResponseMonthPlan, patchMonthlyPlan } from "api/monthPlan";
import { CancelButton, SaveButton } from "components/Common";
import DialogText from "components/DialogText";
import { useErrorHandler } from "components/ErrorHandler";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { autoSelectCompIdState, isEditedState } from "recoil/stateStore";
import { ReactComponent as ProgressIcon } from "assets/writeIcon/progress.svg";
import { ReactComponent as InprogressIcon } from "assets/writeIcon/inprogress.svg";
import { ReactComponent as CompleteIcon } from "assets/writeIcon/complete.svg";
import { ReactComponent as DropIcon } from "assets/writeIcon/notprogress.svg";
import { ReactComponent as CalendarIcon } from "assets/writeIcon/calendar.svg";
import { ReactComponent as UserIcon } from "assets/writeIcon/user.svg";
import { ReactComponent as CompanyIcon } from "assets/writeIcon/company.svg";
import { ReactComponent as MoneyIcon } from "assets/writeIcon/money.svg";
import { ReactComponent as ExpectedMoneyIcon } from "assets/writeIcon/expected-money.svg";
import { ReactComponent as DeliveryDateIcon } from "assets/writeIcon/delivery-date.svg";
import { ReactComponent as ExpectedGraphIcon } from "assets/writeIcon/expected-graph.svg";
import { ReactComponent as GraphIcon } from "assets/writeIcon/graph.svg";
import PlanDate from "components/PlanDate";
import AutoCompleteCompany from "components/AutoCompleteCompany";

interface EditWorkFormProps {
  monthPlanData: ResponseMonthPlan;
  setMonthPlanData: Function;
  planId: number;
}

const EditMonthForm: React.FC<EditWorkFormProps> = ({
  monthPlanData,
  setMonthPlanData,
  planId,
}) => {
  const loginUserName = localStorage.getItem("user_name");
  const loginUserIdStr = localStorage.getItem("user_id");
  const loginUserId: string = loginUserIdStr ? loginUserIdStr : "";
  const [openDialog, setOpenDialog] = useState(false);
  const handleError = useErrorHandler();
  const [year, setYear] = useState<string>(monthPlanData.mplan_year);
  const [month, setMonth] = useState<string>(monthPlanData.mplan_month);
  const [isNewComp, setIsNewComp] = useState<boolean>(
    monthPlanData.is_newCompany
  );
  const [compId, setCompId] = useState<number | null>(
    monthPlanData.company?.comp_id ?? -1
  );
  const [expectedPrice, setExpectedPrice] = useState<number | null>(
    monthPlanData.expected_price
  );
  const [confirmedPrice, setConfirmedPrice] = useState<number | null>(
    monthPlanData.confirmed_price
  );
  const [delivery, setDelivery] = useState<string>(monthPlanData.delivery_date);
  const [exOrderRate, setExOrderRate] = useState<number>(
    monthPlanData.expected_order_rate
  );
  const [orderRate, setOrderRate] = useState<number>(monthPlanData.order_rate);
  const [note, setNote] = useState<string>(monthPlanData.mplan_note);
  const [planDetail, setPlanDetail] = useState<string>(
    monthPlanData.mplan_detail
  );
  const [progress, setProgress] = useState<string>(
    monthPlanData.mplan_progress
  );
  const [autoSelectCompId, setAutoSelectedCompId] = useRecoilState(
    autoSelectCompIdState
  );
  const [expectedFocused, setExpectedFocused] = useState(false);
  const [confirmedFocused, setConfirmedFocused] = useState(false);
  const setIsEdit = useSetRecoilState(isEditedState);

  const onCancel = () => {
    setOpenDialog(true);
  };

  const setDate = (year: string, month: string) => {
    setYear(year);
    setMonth(month);
  };

  const formatCurrency = (value: number | null) => {
    if (value === null) return "";
    return value.toLocaleString("ko-KR");
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const value = e.target.value.replace(/,/g, "");
    const numericValue = parseInt(value, 10);
    if (!isNaN(numericValue)) {
      type === "expected"
        ? setExpectedPrice(numericValue)
        : setConfirmedPrice(numericValue);
    } else {
      type === "expected"
        ? setExpectedPrice(0)
        : setConfirmedPrice(0);
    }
  };

  useEffect(() => {
    console.log(monthPlanData.company?.comp_id, compId);
    setCompId(autoSelectCompId);
  }, [autoSelectCompId]);

  useEffect(() => {
    setAutoSelectedCompId(monthPlanData.company?.comp_id ?? -1);
  }, []);

  useEffect(() => {
    if (confirmedPrice !== null && expectedPrice !== null) {
      const price = ((confirmedPrice / expectedPrice) * 100).toFixed(2) ?? 0;
      setOrderRate(Number(price));
    }
  }, [confirmedPrice, expectedPrice]);

  // 저장버튼
  const handleSubmit = async () => {
    console.log("수정 저장");
    if (compId === null || expectedPrice === null) {
      alert("필수값이 입력되지 않았습니다.");
    } else {
      try {
        const data = {
          user_id: loginUserId,
          mplan_year: year,
          mplan_month: month,
          is_newCompany: isNewComp,
          comp_id: compId,
          expected_price: expectedPrice ?? 0,
          confirmed_price: confirmedPrice ?? 0,
          delivery_date: delivery,
          expected_order_rate: exOrderRate ?? 0,
          order_rate: orderRate,
          mplan_note: note,
          mplan_detail: planDetail,
          mplan_progress: progress,
        };
        const updateMonthPlan = await patchMonthlyPlan(planId, data);
        setMonthPlanData(updateMonthPlan);
        // successSave();
        setIsEdit(false);
      } catch (error) {
        handleError(error); // 오류 처리 함수 호출
        console.log("edited worklog not saved");
      }
    }
  };

  return (
    <div>
      <div className="write_list_wrapper">
        <div className="work_option">
          <div>
            <div className="div_icon" style={{ marginRight: "15px" }}>
              <CalendarIcon style={{ marginRight: "4px" }} />
              연도·월
            </div>
            <div className="mplan_date" style={{ width: "60%" }}>
              <PlanDate
                setDate={setDate}
                selectedYDate={year}
                selectedMDate={month}
              />
            </div>
          </div>
          <div>
            <div className="div_icon" style={{ marginRight: "15px" }}>
              <CompanyIcon style={{ marginRight: "4px" }} />
              업체구분
            </div>
            <div className="check_radio">
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  id="existing_company"
                  name="is_newCompany"
                  value="existing"
                  style={{
                    marginRight: "4px",
                  }}
                  checked={!isNewComp}
                  onChange={({ target }) =>
                    setIsNewComp(target.value === "new")
                  }
                />
                <label
                  htmlFor="existing_company"
                  style={{ color: "rgb(0,0,0,0.6)" }}
                >
                  기존업체
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="radio"
                  id="new_company"
                  name="is_newCompany"
                  value="new"
                  style={{
                    marginRight: "4px",
                  }}
                  checked={isNewComp}
                  onChange={({ target }) =>
                    setIsNewComp(target.value === "new")
                  }
                />
                <label
                  htmlFor="new_company"
                  style={{ color: "rgb(0,0,0,0.6)" }}
                >
                  신규업체
                </label>
              </div>
            </div>
          </div>
          <div>
            <div className="div_icon">
              <UserIcon style={{ marginRight: "4px" }} /> 담당자
            </div>

            <span>
              {loginUserName} ({loginUserId})
            </span>
          </div>
          <div>
            <div className="div_icon">
              <CompanyIcon style={{ marginRight: "4px" }} />
              고객사명
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <AutoCompleteCompany />
          </div>
          <div>
            <div className="div_icon">
              <ExpectedMoneyIcon style={{ marginRight: "4px" }} />
              상담 예상액
              <span style={{ color: "red" }}>&nbsp;*</span>
            </div>
            <input
              type="text"
              value={expectedFocused ? expectedPrice || "" : formatCurrency(expectedPrice)}
              onChange={(e) => handleInputChange(e, "expected")}
              onFocus={() => { setExpectedFocused(true) }}
              onBlur={() => { setExpectedFocused(false) }}
              placeholder="₩"
            />
          </div>
          <div>
            <div className="div_icon">
              <MoneyIcon style={{ marginRight: "4px" }} />
              수주액
            </div>
            <input
              type="text"
              value={confirmedFocused ? confirmedPrice || "" : formatCurrency(confirmedPrice)}
              onChange={(e) => handleInputChange(e, "confirmed")}
              onFocus={() => { setConfirmedFocused(true) }}
              onBlur={() => { setConfirmedFocused(false) }}
              placeholder="₩"
            />
          </div>
          <div>
            <div className="div_icon">
              <DeliveryDateIcon style={{ marginRight: "4px" }} />
              납품시기
            </div>
            <input
              type="text"
              value={delivery}
              onChange={({ target }) => setDelivery(target.value)}
            />
          </div>
          <div>
            <div className="div_icon">
              <ExpectedGraphIcon style={{ marginRight: "4px" }} />
              예상 수주율
            </div>
            <input
              type="number"
              value={exOrderRate ?? ""}
              onChange={({ target }) => setExOrderRate(parseInt(target.value))}
              placeholder="%"
            />
          </div>
          <div>
            <div className="div_icon">
              <GraphIcon style={{ marginRight: "4px" }} />
              수주율
            </div>
            <input
              type="number"
              value={orderRate}
              placeholder="%(자동)"
              readOnly
            />
          </div>

          <div className="textarea_wrapper">
            <div className="div_icon">업무내용</div>
            <textarea
              name="note"
              value={note}
              style={{ color: "#000", height: "110px" }}
              disabled={false}
              onChange={({ target }) => setNote(target.value)}
            />
          </div>
          <div className="textarea_wrapper">
            <div className="div_icon" style={{ marginTop: "16px" }}>
              상세내용 & 영업활동 분석
            </div>
            <textarea
              style={{ color: "#000", height: "110px" }}
              name="plan_detail"
              value={planDetail}
              disabled={false}
              onChange={({ target }) => setPlanDetail(target.value)}
            />
          </div>
          <div className="work_process">
            <div className="div_icon">
              <ProgressIcon style={{ marginRight: "4px" }} />
              진행상황
            </div>
            <select
              value={progress}
              onChange={({ target }) => setProgress(target.value)}
            >
              <option value="NONE">예정</option>
              <option value="INPROGRESS">진행중</option>
              <option value="DROP">미진행</option>
              <option value="COMPLETE">완료</option>
            </select>
          </div>
        </div>
        <div className="write_bottom">
          <>
            <DialogText
              open={openDialog}
              content="변경 사항이 저장되지 않을 수 있습니다. 계속하시겠습니까?"
              onCancel={() => setOpenDialog(false)}
              onClose={() => setIsEdit(false)}
            />
            <CancelButton onClickHandler={onCancel} />
            <SaveButton onClickHandler={handleSubmit} />
          </>
        </div>
      </div>
    </div>
  );
};

export default EditMonthForm;
