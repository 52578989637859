import { Company } from "api/company";
import { atom, selector } from "recoil";
import { ResponseNewMember } from "../api/member";
import { LoginUserInfo, UserInfo, UserTypes } from "api/user";

export const serverIPState = atom<string>({
  key: "serverIpKey",
  default: "",
});

export const serverIPSelector = selector<string>({
  key: "serverIpSelector",
  get: ({ get }) => {
    const serverIP = localStorage.getItem("serverIP");
    if (serverIP) {
      return serverIP;
    } else {
      return "";
    }
  },
  set: ({ set }, newValue) => set(serverIPState, newValue),
});

export const isSideBarOpenState = atom<boolean>({
  key: "isSideBarOpenKey",
  default: true,
});

export const isAccordionExpendedState = atom<string>({
  key: "isAccodionExpendedKey",
  default: "",
});

export const selectedIndexState = atom<string | null>({
  key: "selectedIndexKey",
  default: "",
});

// 모달 open close
export const isDrawerOpenState = atom<boolean>({
  key: "isDrawerOpenKey",
  default: false,
});

// 비밀번호 모달 open close
export const isModalPwdOpenState = atom<boolean>({
  key: "isModalPwdOpenKey",
  default: false,
});

// dialog
export const openDialogState = atom<boolean>({
  key: "openDialogKey",
  default: false,
});

export const openDeleteDialogState = atom<boolean>({
  key: "openDeleteDialogKey",
  default: false,
});

export const dialogContentState = atom<string>({
  key: "dialogContentKey",
  default: "",
});

export const userEditState = atom<boolean>({
  key: "userEditKey",
  default: false,
});

// admin check
export const checkAdminState = atom<string>({
  key: "checkAdminKey",
  default: "false",
});

// 게시글 상태 (생성/보기/수정)
export const modeState = atom<"create" | "view" | "edit">({
  key: "modeKey",
  default: "view",
});

// export const rowDataState = atom<RowData[]>({
//   key: "rowDataStateKey", // Atom의 고유 키
//   default: [], // 기본 값 (초기 상태)
// });

// const defaultWorkLog: ResponseWorkLog = {
//   work_id: -1,
//   work_date: new Date(),
//   user: {
//     user_id: "",
//     user_name: "",
//     dep_name: "",
//     role_name: "",
//   },
//   company: {
//     comp_id: -1,
//     comp_name: "",
//     comp_sido: "",
//     comp_sgg: "",
//   },
//   member: {
//     member_id: -1,
//     member_name: "",
//     member_department: "",
//     member_role: "",
//     member_phone: "",
//   },
//   work_option: "",
//   meeting: "",
//   work_content: "",
//   plan: "",
//   progress: "",
// };

// 선택된 게시글 data (업무일지)
// export const selectedWorkLogState = atom<ResponseWorkLog>({
//   key: "selectedWorkLogKey",
//   default: defaultWorkLog,
// });

// auto complete companyId 선택
export const autoSelectCompIdState = atom<number | null>({
  key: "autoSelectCompIdKey",
  default: null,
});

const defaultCompany: Company = {
  comp_id: -1,
  comp_name: "",
  comp_sido_nm: "",
  comp_sgg_nm: "",
  comp_address: "",
  sectors: "",
  comp_tel: "",
  comp_fax: "",
};

// 업체 담당자 정보 선택된 담당자
export const selectedMemberIdState = atom<number>({
  key: "selectedMemberIdKey",
  default: -1,
});
// 업체별 업무일지 표에서 선택된 업무일지
export const selectedWorksIdState = atom<number>({
  key: "selectedSalesIdKey",
  default: -1,
});

// 선택된 게시글 data (업체현황)
export const selectedCompanyDataState = atom<Company>({
  key: "selectedCompanyDataKey",
  default: defaultCompany,
});

const defaultUser: UserInfo = {
  user_id: "",
  user_name: "",
  department: {
    dep_id: -1,
    dep_name: "",
  },
  role: {
    role_id: -1,
    role_name: "",
  },
  email: "",
  work_phone_number: "",
  phone_number: "",
  is_car: false,
  is_admin: false,
  user_note: "",
  is_deleted: false,
};

// 업체현황 detail open close
export const isDetailOpenState = atom<boolean>({
  key: "isDetailOpenKey",
  default: false,
});

export const userState = atom<UserTypes>({
  key: "userStateKey",
  default: {
    user_id: "",
    password: "",
  },
});

export const checkSaveIdState = atom<boolean>({
  key: "checkSaveIdKey",
  default: false,
});

export const loginUserState = atom<string>({
  key: "loginUserKey",
  default: localStorage.getItem("user_id") || "",
});

export const selectedMenuState = atom<[string, string]>({
  key: "selectedMenuKey",
  default: ["업무관리", "업무일지"],
});

export const btnTextState = selector<string>({
  key: "btnTextKey",
  get: ({ get }) => {
    const pageTitle = get(selectedMenuState)[1];

    switch (pageTitle) {
      case "업무일지":
        return "업무일지 작성";
      case "차량운행일지":
        return "차량운행일지 작성";
      case "업체현황":
        return "신규 업체 등록";
      case "상담일지":
        return "신규상담 등록";
      case "차량관리":
        return "신규차량 등록";
      case "사용자관리":
        return "신규사용자 등록";
      case "월간계획서":
        return "월간계획서 작성";
      case "연간계획서":
        return "연간계획서 작성";
      case "계정 설정" || "차량정보" || "사용자관리":
        return "";
      default:
        return "";
    }
  },
});

export const viewTitleTextState = selector<string>({
  key: "viewTitleTextKey",
  get: ({ get }) => {
    const pageTitle = get(selectedMenuState)[1];

    switch (pageTitle) {
      case "업무일지":
        return "업무일지 상세보기";
      case "차량운행일지":
        return "차량운행일지 상세보기";
      case "업체현황":
        return "업체 정보 - 수정";
      case "상담일지":
        return "상담일지 상세보기";
      case "월간계획서":
        return "상담일지 상세보기";
      case "연간계획서":
        return "연간계획서 상세보기";
      case "차량정보":
        return "차량정보 상세보기";
      case "사용자정보":
        return "사용자정보 상세보기";
      case "차량관리":
        return "차량관리 상세보기";
      case "사용자관리":
        return "사용자관리 상세보기";
      default:
        return "";
    }
  },
});

export const clickedButtonState = atom<string>({
  key: "clickedButtonKey",
  default: "",
});

export const selectedDateButtonState = atom<string>({
  key: "selectedDateButtonKey",
  default: "all",
});
const today = new Date();

// 1주일 전 날짜 계산
const twoWeekAgo = new Date(today);
twoWeekAgo.setDate(today.getDate() - 14);
const twoWeekAgoString = twoWeekAgo.toISOString().split("T")[0];

// 1주일 후 날짜 계산
const twoWeekLater = new Date(today);
twoWeekLater.setDate(today.getDate() + 14);
const twoWeekLaterString = twoWeekLater.toISOString().split("T")[0];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

console.log(currentYear, currentMonth);

export const searchRangeDateState = atom<[string, string]>({
  key: "searchRangeDateKey",
  default: [twoWeekAgoString, twoWeekLaterString],
});

export const selectedDateFilterState = atom<string>({
  key: "selectedDateFilterKey",
  default: "",
});

/* 월간/연간 계획서 선택된 연월 유지 */
export const selectedPlanDateYearState = atom<string>({
  key: "selectedYearPlanDateKey",
  default: currentYear.toString(),
});

export const selectedPlanDateMonthState = atom<string>({
  key: "selectedPlanDateMonthKey",
  default: currentMonth.toString(),
});

// 선택된 게시글  data (사용자 관리)
export const selectedUserDataState = atom<UserInfo>({
  key: "selectedUserDataKey",
  default: defaultUser,
});

const defaultMember: ResponseNewMember = {
  company: {
    comp_id: -1,
    comp_name: "",
  },
  member_id: -1,
  member_name: "",
  member_department: "",
  member_role: "",
  member_phone: "",
  member_email: "",
  member_note: "",
  is_deleted: false,
};

// 선택된 게시글 data (업체 담당자 정보)
export const selectedMemberState = atom<ResponseNewMember>({
  key: "selectedMemberKey",
  default: defaultMember,
});

// =============search

export const tableDataState = atom<any[]>({
  key: "tableDataKey",
  default: [],
});

export const keywordState = atom<string>({
  key: "keywordState",
  default: "",
});

export const subMemberDataState = atom<any[]>({
  key: "subMemberDataKey",
  default: [],
});

export const subSalesDataState = atom<any[]>({
  key: "subSalesDataKey",
  default: [],
});

export const subMemberItemsState = atom<number>({
  key: "subMemberItemsKey",
  default: 0,
});

export const subSalesItemsState = atom<number>({
  key: "subSalesItemsKey",
  default: 0,
});

export interface SearchParams {
  search_type: number;
  keyword: string;
  start_date: string;
  end_date: string;
  page: number;
  pageSize: number | null;
  sort: number;
  user_id?: string | null;
  car_id?: number | null;
  comp_id?: number | null;
  is_personal?: number | null;
  work_id?: number | null;
  progress?: string | "";
}

export const searchOptionState = atom<SearchParams>({
  key: "searchOptionKey",
  default: {
    search_type: 0,
    keyword: "",
    start_date: "",
    end_date: "",
    page: 1,
    pageSize: 12,
    sort: 0,
    user_id: "",
    car_id: null,
    comp_id: null,
    is_personal: null,
    work_id: null,
    progress: "",
  },
});

export const filterUserState = atom<string>({
  key: "filterUserKey",
  default: "",
});

export const filterCarState = atom<number | null>({
  key: "filterCarKey",
  default: null,
});

export const filterPersonalState = atom<number | null>({
  key: "filterPersonalKey",
  default: null,
});

export const filterCompanyState = atom<number | null>({
  key: "filterCompanyKey",
  default: null,
});

export const filterProgressState = atom<string | "">({
  key: "filterProgressKey",
  default: "",
});

// 페이지
export const pageState = atom<number>({
  key: "pageKey",
  default: 1,
});

// item 수
export const totalCountState = atom<number>({
  key: "totalCountKey",
  default: 0,
});

// 업무용 누계
export const prevSumState = atom<number>({
  key: "prevSumKey",
  default: 0,
});

// 임시유저 - 수정 진행 유저
export const updateUserIdState = atom<string>({
  key: "updateUserIdKey",
  default: "",
});

// 선택유저
export const selectedUserState = atom<string>({
  key: "selectedUser",
  default: "",
});

const loginUserInfo: LoginUserInfo = {
  user_id: localStorage.getItem("user_id") || "",
  user_name: localStorage.getItem("user_name") || "",
  is_admin: localStorage.getItem("is_admin") === "true",
  user_role: localStorage.getItem("user_role") || "",
  token: localStorage.getItem("token") || "",
};

export const loginUserInfoState = atom<LoginUserInfo>({
  key: "loggingUserInfo",
  default: loginUserInfo,
});

// Drawer Edit 상태표시
export const isEditedState = atom<Boolean>({
  key: "isEdited",
  default: false,
});

// workLog
export const workListState = atom<any[]>({
  key: "workList",
  default: [],
});

//userList
export const userListState = atom<any[]>({
  key: "userList",
  default: [],
});
