import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ReactComponent as Arrow } from "assets/arrow.svg";
import { ColDef } from "ag-grid-community";
import "styles/Chart.css";
import { getCompany } from "api/company";
import { ResponseCar } from "api/car";
import DateSearch from "./DateSearch";
import { useRecoilValue } from "recoil";
import { isEditedState, loginUserInfoState } from "recoil/stateStore";
import { downLoadCarLog, patchCarLogApproval } from "api/carLog";
import { CircularProgress } from "@mui/material";
import UpdatedRowData from "./UpdatedRowData";
import { LoginUserInfo } from 'api/user';

interface GridProps {
  columnDefs: ColDef[];
  rowData: any[];
  type: string;
  setOpen: (status: boolean, type: string) => void;
  setId: (type: string, id: number) => void;
  carList?: ResponseCar[];
  setCarId?: (carId: number | null) => void;
  newData?: any;
  deletedId?: number;
  setRowData?: (data: any[]) => void;
  rowDataLoading?: boolean;
}
const Grid: React.FC<GridProps> = ({
  columnDefs,
  rowData,
  type,
  setOpen,
  setId,
  carList,
  setCarId,
  newData,
  deletedId,
  setRowData,
  rowDataLoading,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const navigate = useNavigate();
  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const filterInputRef = useRef<HTMLInputElement>(null);
  const [view, setView] = useState<number>(10);
  const [gridHeight, setGridHeight] = useState<number>(500);
  const [selectedCarNum, setSelectedCarNum] = useState<string>("선택하세요");
  const isEdit = useRecoilValue(isEditedState);
  const [loading, setLoading] = useState<boolean>(false);
  // const [rowDataLoading, setRowDataLoading] = useState<boolean>(false);
  const selectedCarRef = useRef<HTMLDivElement | null>(null);
  const [isListVisible, setIsListVisible] = useState(false);
  const loginUserInfo = useRecoilValue(loginUserInfoState);

  const handleExcel = async () => {
    const carData = carList?.find((car) => car.car_num === selectedCarNum);
    if (carData) {
      await downLoadCarLog(carData.car_id);
    } else {
      console.error("Car data not found");
    }
  };

  useEffect(() => {
    if (view === 10) {
      setGridHeight(480);
    } else if (view === 25) {
      setGridHeight(1100);
    } else {
      setGridHeight(500);
    }
  }, [view]);

  const toggleDrawer = () => {
    setOpen(true, type);
  };

  useEffect(() => {
    if (!isEdit && newData) {
      if (type === "workLog") {
        updateRow(newData.work_id);
      } else if (type === "workStatus") {
        updateRow(newData.comp_id);
      } else if (type === "counseling") {
        updateRow(newData.cons_id);
      } else if (type === "carLog") {
        updateRow(newData.carlog_id);
      } else if (type === "car") {
        updateRow(newData.car_id);
      } else if (type === "user") {
        updateRow(newData.user_id);
      }
    }
  }, [newData]);

  useEffect(() => {
    if (deletedId !== -1) {
      onRowDelete();
    }
  }, [deletedId]);

  const gridOptions = {
    getRowId: (params: any) => {
      if (type === "workLog") {
        return String(params.data.work_id);
      } else if (type === "workStatus") {
        return String(params.data.comp_id);
      } else if (type === "counseling") {
        return String(params.data.cons_id);
      } else if (type === "carLog") {
        return String(params.data.carlog_id);
      } else if (type === "car") {
        return String(params.data.car_id);
      } else if (type === "user") {
        return String(params.data.user_id);
      }
      return "";
    },
  };

  const onRowDelete = () => {
    const id = String(deletedId);
    if (gridRef.current && gridRef.current.api && type !== "car") {
      const rowNode = gridRef.current.api.getRowNode(id);
      if (rowNode) {
        gridRef.current.api.applyTransaction({ remove: [rowNode.data] });
      }
    }
  };

  const updateRow = (id: number) => {
    if (gridRef.current && gridRef.current.api) {
      if (!newData || !id) {
        console.log("Invalid data or missing id in newData.");
        return;
      }
      const rowNode = gridRef.current.api.getRowNode(String(id));
      if (!rowNode) {
        console.error(`Row with id: ${newData.id} not found.`);
        return;
      }
      const updatedData = UpdatedRowData(newData);

      gridRef.current.api.applyTransaction({
        update: [updatedData],
      });
      console.log(`Row with id: ${id} updated:`, updatedData);
    } else {
      console.log("Grid API is not initialized.");
    }
  };

  const onRowClicked = async (event: any) => {
    if (event.event.target.closest("input")) {
      return; // 버튼 클릭 시 함수 종료
    }
    if (type === "workLog") {
      const workId = event.data.work_id;
      try {
        setId("work", workId);
        toggleDrawer();
      } catch (error) {
        console.error("Error fetching work log data:", error);
      }
    } else if (type === "workStatus") {
      const compId = event.data.comp_id;
      // setSelectedId(compId)
      try {
        const compData = await getCompany(compId);
        const responseData = compData.data;

        if (responseData && responseData.comp_name) {
          navigate(`/companies/${responseData.comp_name}`, {
            state: { id: compId },
          });
        } else {
          console.error("유효하지 않은 회사 데이터:", responseData);
        }
      } catch (error) {
        console.error("Error fetching work state data:", error);
      }
    } else if (type === "counseling") {
      const consId = event.data.cons_id;
      // setSelectedId(consId)
      try {
        setId("cons", consId);
        toggleDrawer();
      } catch (error) {
        console.error("Error fetching counseling data:", error);
      }
    } else if (type === "carLog") {
      const carLogId = event.data.carlog_id;
      // setSelectedId(carLogId)
      try {
        setId("carLogId", carLogId);
        toggleDrawer();
      } catch (error) {
        console.error("Error fetching CarLog data:", error);
      }
    } else if (type === "user") {
      const userId = event.data.user_id;
      // setSelectedId(userId)
      try {
        setId("user", userId);
        toggleDrawer();
      } catch (error) {
        console.error("Error fetching userInfo data:", error);
      }
    } else if (type === "car") {
      const carId = event.data.car_id;
      // setSelectedId(carId)
      try {
        setId("car", carId);
        // console.log(carId)
        toggleDrawer();
      } catch (error) {
        console.error("Error fetching carInfo data:", error);
      }
    }
  };

  const handleOnChangeSelect = (carId: number) => {
    const carData = carList?.find((car) => car.car_id === carId);
    if (setCarId) {
      if (carId === -1) {
        setCarId(null);
        setSelectedCarNum("선택하세요");
      } else {
        setCarId(carId);
        setSelectedCarNum(carData?.car_num || "");
      }

      setIsListVisible(false);
      //   setSelectedCarNum(target);
      //   const selectedCar = carList?.find(car => car.car_num === target);
      //   if (selectedCar) {
      //     // car_id를 저장
      //     setCarId(selectedCar.car_id);
      //     console.log(selectedCar.car_id, "carID")
      //   } else { console.log(target, "no CarId") }
    }
  };

  const toggleListVisibility = () => {
    setIsListVisible((prev) => !prev);
  };

  //필터 리셋
  const resetFilters = () => {
    gridRef.current?.api.setFilterModel(null);
    gridRef.current?.api.onFilterChanged();
    setQuickFilterText("");
    if (filterInputRef.current) {
      filterInputRef.current.value = "";
    }
  };

  const defaultColDef: ColDef = {
    resizable: true,
    sortable: true,
    flex: 1,
  };

  const handleApproveAll = async () => {
    setLoading(true); // 로딩 시작
    try {
      const updatedRowData = await Promise.all(
        rowData.map(async (car) => {
          await patchCarLogApproval(car.carlog_id, {
            ...car,
            is_approval: true,
          });
          return { ...car, is_approval: true }; // 승인된 차량 데이터 반환
        })
      );
      if (setRowData) {
        setRowData(updatedRowData); // 테이블 데이터 업데이트
      }

      console.log("모든 차량이 승인되었습니다.");
    } catch (error) {
      console.error("모두 승인하기 중 오류 발생:", error);
    } finally {
      setLoading(false); // 로딩 종료
    }
  };

  useEffect(() => {
    if (selectedCarRef.current) {
      selectedCarRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [isListVisible]);

  const localeText = {
    // Set locale text to Korean
    page: "페이지",
    more: "더 보기",
    to: "까지",
    of: "중",
    next: "다음",
    last: "마지막",
    first: "처음",
    previous: "이전",
    loadingOoo: "로딩 중...",
    selectAll: "전체 선택",
    searchOoo: "검색...",
    blanks: "빈 값",
    filterOoo: "필터...",
    applyFilter: "필터 적용",
    equals: "같음",
    notEqual: "같지 않음",
    lessThan: "보다 작음",
    greaterThan: "보다 큼",
    lessThanOrEqual: "보다 작거나 같음",
    greaterThanOrEqual: "보다 크거나 같음",
    inRange: "기간 설정",
    before: "설정 기간 까지",
    after: "설정 기간 이후",
    contains: "포함",
    notContains: "포함하지 않음",
    startsWith: "시작이 같음",
    endsWith: "끝이 같음",
    blank: "빈 값",
    // Custom filters
    dateFormatOoo: "yyyy-mm-dd",
  };

  return (
    <div className="grid-container">
      <div className="filter">
        <div>
          {type === "carLog" || type === "workLog" || type === "counseling" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <DateSearch />
              {type === "carLog" && loginUserInfo.is_admin && (
                <div className="approval_container">
                  <button onClick={handleApproveAll} disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : "모두 승인하기"}
                  </button>
                </div>
              )}
            </div>
          ) : null}
          {type === "carLog" ? (
            <div className="carSelect_container">
              <button
                disabled={selectedCarNum === "선택하세요"}
                onClick={handleExcel}
              >
                Excel 다운로드
              </button>
              <label>차량 :</label>
              <div
                className="custom-select"
                tabIndex={0}
                style={{ width: "130px", zIndex: 1000 }}
                onClick={toggleListVisibility}
              >
                <div className="selected-value">
                  {selectedCarNum}
                  <span>
                    <Arrow />
                  </span>
                </div>
                {carList && !isListVisible && (
                  <div className="custom-select-list">
                    <div
                      className="option"
                      onClick={() => handleOnChangeSelect(-1)}
                    >
                      선택하세요
                    </div>
                    {carList.map((car) => (
                      <div
                        key={car.car_id}
                        onClick={() => handleOnChangeSelect(car.car_id)}
                        className={`option ${selectedCarNum === car.car_num ? "selected" : ""
                          }`}
                        ref={
                          selectedCarNum === car.car_num ? selectedCarRef : null
                        }
                      >
                        {car.car_num}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <div className="wordSearch_container">
            <label>검색 :</label>
            <input
              ref={filterInputRef}
              type="text"
              placeholder="검색어를 입력하세요"
              onChange={(e) => setQuickFilterText(e.target.value)}
            />
            <button className="resetBtn" onClick={resetFilters}>
              필터 리셋
            </button>
          </div>
          <div className="viewSize_btn_box">
            <label>View Size :</label>
            <button
              className={view === 10 ? "viewSize_btn selected" : "viewSize_btn"}
              onClick={() => setView(10)}
            >
              10
            </button>
            <button
              className={view === 25 ? "viewSize_btn selected" : "viewSize_btn"}
              onClick={() => setView(25)}
            >
              25
            </button>
          </div>
        </div>
      </div>

      <div className="ag-theme-alpine" style={{ height: `${gridHeight}px` }}>
        <AgGridReact
          ref={gridRef}
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          rowData={rowData}
          quickFilterText={quickFilterText}
          defaultColDef={defaultColDef}
          localeText={localeText}
          popupParent={document.body} //필터 누르면 표하단 가려지는 문제 해결용
          onRowClicked={onRowClicked}
          rowSelection="single"
          rowDragManaged={true} // 행 드래그 관리 활성화
          animateRows={true} // 행 이동 시 애니메이션 활성화
          loading={rowDataLoading}
          overlayNoRowsTemplate={"데이터가 존재하지 않습니다."} // 데이터 없는 경우 뜨는 메세지
          overlayLoadingTemplate={"...Loading"}
          getRowClass={(params) => {
            if (type === "user" || type === "car") {
              const isDeleted = params.data.is_deleted;
              if (isDeleted === "삭제") {
                return "row-is_deleted"; // 삭제되지 않은 경우 빨간색
              }
            }
            return ""; // 기본 값
          }}
        />
      </div>
    </div>
  );
};

export default Grid;
