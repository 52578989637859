import React, { useState, useRef, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ColDef } from "ag-grid-community";
import "styles/Chart.css";
import { ResponseMonthPlan } from "api/monthPlan";
import { useRecoilValue } from "recoil";
import { isEditedState } from "recoil/stateStore";

interface MonthGridProps {
  name?: string;
  columnDefs: ColDef[];
  rowData: any[];
  setOpen: (status: boolean) => void;
  setId: (type: string, id: number) => void;
  newData: ResponseMonthPlan | undefined;
  deletedId: number;
}

const MonthGrid: React.FC<MonthGridProps> = ({
  name,
  columnDefs,
  rowData,
  setOpen,
  setId,
  newData,
  deletedId,
}) => {
  const gridRef = useRef<AgGridReact | null>(null);
  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const filterInputRef = useRef<HTMLInputElement>(null);
  const isEdit = useRecoilValue(isEditedState);

  useEffect(() => {
    if (!isEdit) updateRow();
  }, [newData]);

  // 데이터 삭제시 행 삭제
  useEffect(() => {
    if (deletedId !== -1) {
      onRowDelete();
    }
  }, [deletedId]);

  useEffect(() => {
    updatePinnedData();
  }, [rowData, gridRef]);

  // 총합 데이터 표시
  const updatePinnedData = () => {
    if (rowData && name === "고객사상담현황") {
      console.log(rowData, columnDefs);
      if (gridRef.current && gridRef.current.api) {
        // pinnedBottomRowData 초기화
        gridRef.current.api.setGridOption("pinnedBottomRowData", []);

        const newPinnedBottomData = [
          {
            no: "총합: ",
            user_name: "-",
            comp_name: "-",
            ex_price: calculateSum("ex_price").toLocaleString("ko-KR") + " 원",
            con_price:
              calculateSum("con_price").toLocaleString("ko-KR") + " 원",
            delivery_date: "-",
            ex_order_rate: calculateAvg("expected_order_rate") + " %",
            con_order_rate: (calculateOrderRate() || 0) + " %",
            mplan_note: "-",
            customStyle: { backgroundColor: "#eeeeeecc" },
          },
        ];
        gridRef.current.api.setGridOption(
          "pinnedBottomRowData",
          newPinnedBottomData
        );
        console.log(newPinnedBottomData, "pinnedBottomData");
      }
    }
  };

  const onRowDelete = () => {
    const id = String(deletedId);
    if (gridRef.current && gridRef.current.api) {
      const rowNode = gridRef.current.api.getRowNode(id);
      if (rowNode) {
        gridRef.current.api.applyTransaction({ remove: [rowNode.data] });
      }
    }
  };

  const updateRow = () => {
    if (gridRef.current && gridRef.current.api) {
      if (!newData || !newData.mplan_id) {
        return;
      }
      console.log(newData, "newData");
      const id = String(newData.mplan_id);
      const rowNode = gridRef.current.api.getRowNode(id);
      if (!rowNode) {
        return;
      }

      const updatedData = {
        ...newData,
        no: rowNode.data.no,
        user: newData.user?.user_name,
        company: newData.company?.comp_name,
        ex_price: (newData.expected_price ?? 0).toLocaleString("ko-KR"),
        con_price: (newData.confirmed_price ?? 0).toLocaleString("ko-KR"),
        expected_order_rate: newData.expected_order_rate ?? 0,
        ex_order_rate: newData.expected_order_rate || "0" + " %",
        order_rate: newData.order_rate,
        con_order_rate: newData.order_rate + " %",
        delivery_date: newData.delivery_date || "-",
        mplan_note: newData.mplan_note || "-",
        mplan_detail: newData.mplan_detail || "-",
      };

      gridRef.current.api.applyTransaction({
        update: [updatedData],
      });
      updatePinnedData();
      console.log(
        `Row with mplan_id ${newData.mplan_id} updated:`,
        updatedData
      );
    }
  };

  // 필터 리셋
  const resetFilters = () => {
    const gridApi = gridRef.current?.api;
    if (gridApi) {
      gridApi.setFilterModel(null);
      gridApi.onFilterChanged();
      gridApi.applyColumnState({
        defaultState: { sort: null },
      });
    }
    setQuickFilterText("");
    if (filterInputRef.current) {
      filterInputRef.current.value = "";
    }
  };

  const localeText = {
    contains: "포함",
    notContains: "포함하지 않음",
    equals: "같음",
    dateFormatOoo: "yyyy-mm-dd",
  };

  const toggleDrawer = () => {
    setOpen(true);
  };

  const onRowClicked = async (event: any) => {
    const planId = event.data.mplan_id;
    if (event.data.no === "총합: ") {
      return;
    }
    try {
      setId("planId", planId);
      toggleDrawer();
    } catch (error) {
      console.error("Error fetching mPlan data:", error);
    }
  };

  const gridOptions = {
    getRowId: (params: any) => {
      return String(params.data.mplan_id);
    },
  };

  // 수주율 평균
  const calculateOrderRate = () => {
    const avg = calculateSum("ex_price") / calculateSum("con_price");
    const round = Number(avg.toFixed(2));
    return round
  }

  // 합계 계산
  const calculateSum = (columnId: string) => {
    const gridApi = gridRef.current?.api;
    let sum = 0;

    if (gridApi) {
      gridApi.forEachNode((rowNode) => {
        if (
          columnId === "ex_price" &&
          rowNode.data &&
          rowNode.data.expected_price
        ) {
          sum += rowNode.data.expected_price;
          // console.log(rowNode.data.ex_price, sum, "ex_price")
        } else if (
          columnId === "con_price" &&
          rowNode.data &&
          rowNode.data.confirmed_price
        ) {
          sum += rowNode.data.confirmed_price;
          // console.log(rowNode.data.con_price, sum, "con_price")
        }
      });
    }
    // console.log(sum, "sum");
    return sum;
  };

  // 평균 계산
  const calculateAvg = (columnId: string) => {
    const gridApi = gridRef.current?.api;
    let sum = 0;
    let count = 0;

    if (gridApi) {
      gridApi.forEachNode((rowNode) => {
        if (
          columnId === "expected_order_rate" &&
          rowNode.data &&
          rowNode.data.expected_order_rate
        ) {
          sum += rowNode.data.expected_order_rate;
          count++;
        } else if (
          columnId === "order_rate" &&
          rowNode.data &&
          rowNode.data.order_rate
        ) {
          sum += rowNode.data.order_rate;
          count++;
        }
      });
    }

    const average = count > 0 ? Math.round(sum / count) : 0;
    return average;
  };

  return (
    <>
      <div className="grid-container">
        <div className="filter">
          <div>
            <div className="wordSearch_container">
              <label>검색 :</label>
              <input
                ref={filterInputRef}
                type="text"
                placeholder="검색어를 입력하세요"
                onChange={(e) => setQuickFilterText(e.target.value)}
              />
              <button className="resetBtn" onClick={resetFilters}>
                필터 리셋
              </button>
            </div>
            <div style={{ flex: "1" }}></div>
            <div className="progress-color">
              <div style={{ border: "8px solid #fa5b8a93" }}></div>
              <span> : 예정</span>
              <div style={{ border: "8px solid rgb(251, 251, 186)" }}></div>
              <span> : 진행중</span>
              <div style={{ border: "8px solid rgb(220, 220, 255)" }}></div>
              <span> : 완료</span>
            </div>
          </div>
        </div>
        <div
          className={"ag-theme-alpine"}
          style={
            columnDefs.length === 9 ? { height: "450px" } : { height: "300px" }
          }
        >
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            rowData={rowData}
            quickFilterText={quickFilterText}
            localeText={localeText}
            popupParent={document.body}
            onRowClicked={onRowClicked}
            defaultColDef={{ resizable: true, sortable: true, filter: true }}
            // pinnedBottomRowData={pinnedBottomData}
            suppressRowClickSelection={true}
            overlayNoRowsTemplate={"데이터가 존재하지 않습니다."} // 데이터 없는 경우 뜨는 메세지
            getRowClass={(params) => {
              const planProgress = params.data.mplan_progress;
              if (planProgress === "NONE") {
                return "row-red";
              } else if (planProgress === "COMPLETE") {
                return "row-green";
              } else if (planProgress === "INPROGRESS") {
                return "row-yellow";
              }
              return ""; // 기본 값
            }}
          />
        </div>
      </div>
    </>
  );
};

export default MonthGrid;
